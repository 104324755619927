let ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";

var socket_error = null;
function startWebSocket(reconnect) {
    let connection_string = ws_scheme + '://' + window.location.host + '/ws/chat/';
    console.log('connect to ' + connection_string);
    window.chatSocket = new WebSocket(connection_string);

    let ping_interval = setInterval(function () {
        chatSend({type: 'ping'})
    }, 45000);

    chatSocket.onmessage = function (event) {
        let data = JSON.parse(event.data);
        if (data.error) {
            socket_error = data.error;
        }
        console.log(data);
    };

    chatSocket.onopen = function (e) {
    };

    chatSocket.onclose = function (e) {
        if (!chatSocket || chatSocket.readyState === WebSocket.CLOSED) {
            window.chatSocket = null;
            console.error('Chat socket closed unexpectedly');
            clearInterval(ping_interval);
            if (socket_error != 4003) {
                setTimeout(function () {
                    console.log('reconnect');
                    startWebSocket(true);
                }, 5000);
            } else {
                console.log(`${socket_error} ошибка аутентификации. Прекращаем коннект к сокету!`)
            }
        }
    };

    function chatSend(data) {
        chatSocket.send(JSON.stringify(data));
    }
}


$(document).ready(function () {
    startWebSocket(false);
});
