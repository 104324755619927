class Cameras {

    static truncate_video() {

        let new_start = ~~$("#record_start").data("value") + ~~$("#slider-min").data("value") * 20;
        let new_end = ~~$("#record_start").data("value") + ~~$("#slider-max").data("value") * 20;
        location.href = "/cameras/camera_period/" + $("#camera_id").data("value") + "/" +
            $("#record_start").data("value") + "/" + $("#record_stop").data('value') + "/" +
            "?slider-min=" + new_start + "&slider-max=" + new_end;
    }

    static create_video_button(event) {
        let new_start = ~~$("#record_start").data("value") + ~~$("#slider-min").data("value") * 20;
        let new_end = ~~$("#record_start").data("value") + ~~$("#slider-max").data("value") * 20;
        event.stopPropagation();
        location.href = "/cameras/create_period_record/" + $("#camera_id").data("value") + "/" +
            new_start + "/" + new_end + "/";
        return false;

    }
}

if (document.getElementById("truncate_video")!=null) {
    document.getElementById("truncate_video").addEventListener("click", Cameras.truncate_video)
}
if (document.getElementById("create_video")!=null) {
    document.getElementById("create_video").addEventListener("click", Cameras.create_video_button)
}