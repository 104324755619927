class PresentationAdd {

    static select_conference(obj) {
        // alert('selected');
        obj.form.pressed_button.value = 'select_conf';
        obj.form.submit();
    }

    static add_conference(obj) {
        let conference_name = $(obj).closest("tr").next().find("#id_conference_name").val();
        let conference_id = $("#id_conference").val();
        let presentation_id = $("#id_presentation").val();
        if (!confirm(`Вы действительно хотите добавить конференцию ${conference_name} к общей конференции и установить ее для этого доклада?`)) return false;
        $.ajax({
            url: "/cabinet/api/add_conference_from_text?",
            method: "POST",
            dataType: "json", data: {
                'csrfmiddlewaretoken': $("[name='csrfmiddlewaretoken']").val(),
                'conference_name': conference_name,
                'conference_id': conference_id,
                'presentation_id': presentation_id
            }
        }).done(function (data) {
            $("#id_conf").append(`<option value='${data['conf_id']}'>${data['conference_name']}</option>`);
            $("#id_conf").val(data['conf_id']);
            $("#id_conf").get(0).form.submit();


        }).fail(function (jqXHR, textStatus, errorThrown) {
            alert(errorThrown);
        });
    }
}